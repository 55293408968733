import React, { Component } from 'react'

const setFilterItemClass = active =>
	`${
		active ? 'border-b-4 border-teal' : ''
	} font-bold text-sm-fluid mr-1 lg:mr-1-5 hover:text-orange focus:text-orange outline-none trans trans-color py-0-75`

export default class Filter extends Component {
	render() {
		const { categories, all, onClick, show } = this.props

		return (
			<div className="border-t border-b border-white-30 mb-4">
				<nav className="whitespace-no-wrap wrapper flex overflow-scroll md:overflow-hidden">
					<a
						onClick={e => {
							e.preventDefault()
							onClick('all')
						}}
						href="#0"
						className={setFilterItemClass(show === 'all')}
					>
						all ({all.length})
					</a>
					{categories.map(({ key, length }) => (
						<a
							onClick={e => {
								e.preventDefault()
								onClick(key)
							}}
							href="#0"
							key={key}
							className={setFilterItemClass(show === key)}
						>
							{key} ({length})
						</a>
					))}
				</nav>
			</div>
		)
	}
}
