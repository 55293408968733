import React, { Component } from 'react'
import { Line } from '@/animation'

export default class FeedHeader extends Component {
	shouldComponentUpdate = () => false

	render() {
		const { title, children } = this.props
		return (
			<div className="wrapper relative pt-5 mb-2 lg:mb-5 lg:grid">
				<div className="mx-auto lg:mx-0 lg:col-start-7 lg:col-end-24">
					<div className="flex justify-end lg:block">
						<h1 className="text-h1-fluid font-bold lowercase relative mb-2  relative">
							<Line className="absolute h-5 w-screen vert-center pin-r-100 bg-teal mr-2" />
							{title}
						</h1>
					</div>
					{children}
				</div>
			</div>
		)
	}
}
