import React, { Component, Fragment } from 'react'
import * as R from 'ramda'
import SEO from '@/components/SEO/SEO'
import Filter from '@/components/Filter/Filter'
import FeedHeader from '@/components/FeedHeader/FeedHeader'
import ContentGrid from '@/components/ContentGrid/ContentGrid'
import PostThumb from '@/components/PostThumb/PostThumb'
import OrgThumb from '@/components/OrgThumb/OrgThumb'

export default class SearchResults extends Component {
	constructor(props) {
		super(props)

		const {
			location: { state }
		} = props

		if (state) {
			const { all, categories, count, searchTerm } = state

			this.state = {
				show: 'all',
				all,
				categories,
				current: all,
				count,
				searchTerm
			}
		}
	}

	componentDidUpdate = prevProps => {
		const {
			location: {
				state: { searchTerm: prevSearchTerm }
			}
		} = prevProps

		const {
			location: {
				state: { searchTerm, all, categories, count }
			}
		} = this.props

		if (prevSearchTerm !== searchTerm) {
			this.setState({
				show: 'all',
				all,
				categories,
				current: all,
				count,
				searchTerm
			})
		}
	}

	onClick = show => {
		const { all, categories } = this.state

		this.setState(() => ({
			show,
			current:
				show === 'all'
					? all
					: R.compose(
						R.head,
						R.pluck('value'),
						R.filter(({ key }) => key === show)
					  )(categories)
		}))
	}

	render() {
		if (!this.state) return null
		const { current, all, categories, show, count, searchTerm } = this.state

		return (
			<Fragment>
				<SEO
					title={`your search returned ${count} results for ${searchTerm}`}
				/>
				<FeedHeader title="search">
					<p className="text-xl-fluid">
						your search returned {count} results for “
						<span className="text-orange">{searchTerm}</span>”
					</p>
				</FeedHeader>
				<Filter
					all={all}
					categories={categories}
					show={show}
					onClick={this.onClick}
				/>
				<ContentGrid
					content={current}
					render={item =>
						item.type === 'directory' ? (
							<OrgThumb {...item} />
						) : (
							<PostThumb {...item} />
						)
					}
				/>
			</Fragment>
		)
	}
}
