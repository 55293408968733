import React, { Component } from 'react'
// import { PoseGroup } from 'react-pose'
// import { FadeBox, Stagger } from '@/animation'

export default class ContentGrid extends Component {
	render() {
		const { content, render } = this.props

		return (
			<div className="wrapper">
				<div className="md:flex md:flex-wrap md:-ml-1-5">
					{content.map(item => (
						<div
							className="md:pl-1-5 mb-2 flex flex-col md:w-12/24 lg:w-6/24"
							key={item.id}
						>
							{render(item)}
						</div>
					))}
				</div>
			</div>
		)
	}
}
